export const swap = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  const copiedArr = [...arr];

  const from = copiedArr[fromIndex];
  const to = copiedArr[toIndex];

  copiedArr[fromIndex] = to;
  copiedArr[toIndex] = from;

  return copiedArr;
};
