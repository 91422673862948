import {
  ArrayParam,
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { GranularityEnum } from '@/globalTypes';
import { GranularityParam } from '@/utils/query/params';

export enum TimeRangeEnum {
  LastMonth = 'last-month',
  ThisMonth = 'this-month',
  LastWeek = 'last-week',
  ThisWeek = 'this-week',
  Yesterday = 'yesterday',
  Today = 'today',
  Custom = 'custom',
}

const sharedQueryParams = {
  affiliateId: withDefault(BooleanParam, false),
  allWins: withDefault(BooleanParam, false),
  amountAdjustments: withDefault(BooleanParam, false),
  amountBonusAdjustments: withDefault(BooleanParam, false),
  amountCashbacks: withDefault(BooleanParam, false),
  amountDeposits: withDefault(BooleanParam, false),
  amountFailedDeposits: withDefault(BooleanParam, false),
  amountFailedWithdrawals: withDefault(BooleanParam, false),
  amountFtd: withDefault(BooleanParam, false),
  amountReversals: withDefault(BooleanParam, false),
  amountWithdrawableWinnings: withDefault(BooleanParam, false),
  amountWithdrawals: withDefault(BooleanParam, false),
  birthYear: withDefault(BooleanParam, false),
  brand: withDefault(BooleanParam, false),
  clientCountryCode: withDefault(BooleanParam, false),
  currency: withDefault(BooleanParam, false),
  device: withDefault(BooleanParam, false),
  deviceFingerprint: withDefault(BooleanParam, false),
  exchangeRateBaseCurrency: StringParam,
  exchangeRateDate: DateTimeParam,
  startDate: DateTimeParam,
  endDate: DateTimeParam,
  timeRange: withDefault(StringParam, TimeRangeEnum.ThisMonth),
  ftd: withDefault(BooleanParam, false),
  ftdOfNrc: withDefault(BooleanParam, false),
  gameDescriptor: withDefault(BooleanParam, false),
  gameId: withDefault(BooleanParam, false),
  gameProvider: withDefault(BooleanParam, false),
  gameSessionId: withDefault(BooleanParam, false),
  gender: withDefault(BooleanParam, false),
  ggr: withDefault(BooleanParam, false),
  granularity: withDefault(GranularityParam, GranularityEnum.All),
  hold_: withDefault(BooleanParam, false),
  isBot: withDefault(BooleanParam, false),
  isIncognito: withDefault(BooleanParam, false),
  jpc: withDefault(BooleanParam, false),
  margin: withDefault(BooleanParam, false),
  ngr: withDefault(BooleanParam, false),
  nrc: withDefault(BooleanParam, false),
  nrcByNsc: withDefault(BooleanParam, false),
  nsc: withDefault(BooleanParam, false),
  numAdjustments: withDefault(BooleanParam, false),
  numBlockAccountRequests: withDefault(BooleanParam, false),
  numCancelSelfExclusionRequests: withDefault(BooleanParam, false),
  numCashbacks: withDefault(BooleanParam, false),
  numCloseAccountRequests: withDefault(BooleanParam, false),
  numDeposits: withDefault(BooleanParam, false),
  numFailedDeposits: withDefault(BooleanParam, false),
  numFailedWithdrawals: withDefault(BooleanParam, false),
  numReopenAccountRequests: withDefault(BooleanParam, false),
  numReversals: withDefault(BooleanParam, false),
  numSelfExclusionRequests: withDefault(BooleanParam, false),
  numTotalCloseAccountRequests: withDefault(BooleanParam, false),
  numUniqueActivePlayers: withDefault(BooleanParam, false),
  numUniqueDepositors: withDefault(BooleanParam, false),
  numUniquePlayers: withDefault(BooleanParam, false),
  numUniqueSessions: withDefault(BooleanParam, false),
  numWithdrawals: withDefault(BooleanParam, false),
  os: withDefault(BooleanParam, false),
  paymentProvider: withDefault(BooleanParam, false),
  playerSessionId: withDefault(BooleanParam, false),
  residenceCountryCode: withDefault(BooleanParam, false),
  timePeriod: withDefault(BooleanParam, false),
  timeZone: StringParam,
  turnover: withDefault(BooleanParam, false),
  wagers: withDefault(BooleanParam, false),
  columns: withDefault(ArrayParam, []),
  rows: withDefault(ArrayParam, []),
  values: withDefault(ArrayParam, []),
};

export const viewerQueryParams = {
  ...sharedQueryParams,
  player: withDefault(BooleanParam, false),
  playerId: withDefault(BooleanParam, false),
};

export const playerQueryParams = sharedQueryParams;
