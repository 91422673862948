import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, InlineSpinner } from '@/components';
import { useTranslate } from '@/contexts';
import { SanityAnalyticsBlockStaticQuery } from './__generated__/SanityAnalyticsBlockStaticQuery';
import { AnalyticsProvider } from './Context';
import Settings from './Settings';
import { usePlayerAnalytics } from './usePlayerAnalytics';
import { useViewerAnalytics } from './useViewerAnalytics';
import { VisualizationView } from './visualizations';

const BLOCK_STATIC_QUERY = graphql`
  query SanityAnalyticsBlockStaticQuery {
    sanityAnalyticsBlock {
      title {
        ...LocaleString
      }
    }
  }
`;

const Block = ({
  addParam,
  switchQueryIndexes,
  columns,
  rows,
  values,
  removeParam,
  endDate,
  setEndDate,
  setTimeRange,
  startDate,
  setStartDate,
  timeRange,
  fetching,
  stats,
}: ReturnType<typeof usePlayerAnalytics | typeof useViewerAnalytics>) => {
  const { t } = useTranslate();

  const block = useStaticQuery<SanityAnalyticsBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityAnalyticsBlock;

  if (!block) {
    return null;
  }

  return (
    <AnalyticsProvider>
      <Card size="lg" title={t(block.title)}>
        <CardBody className="p-3">
          <div className="space-y-4">
            <Settings
              switchQueryIndexes={switchQueryIndexes}
              addParam={addParam}
              columns={columns}
              rows={rows}
              values={values}
              removeParam={removeParam}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              setTimeRange={setTimeRange}
              startDate={startDate}
              timeRange={timeRange}
            />
            {fetching ? (
              <div className="grid justify-center">
                <InlineSpinner />
              </div>
            ) : (
              <VisualizationView
                stats={stats ?? []}
                rows={rows ?? []}
                values={values ?? []}
                columns={columns ?? []}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </AnalyticsProvider>
  );
};

const ViewerAnalytics: FC = () => {
  const props = useViewerAnalytics();
  return <Block {...props} />;
};

const PlayerAnalytics: FC<{ playerId: string }> = ({ playerId }) => {
  const props = usePlayerAnalytics({ playerId });
  return <Block {...props} />;
};

const AnalyticsBlock: FC<{}> = () => {
  const { playerId } = useParams();

  if (playerId && typeof playerId === 'string') {
    return <PlayerAnalytics playerId={playerId} />;
  }

  return <ViewerAnalytics />;
};

export default AnalyticsBlock;
