import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';

import { GranularityEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import { PlayerAnalytics_player_stats_rows } from './__generated__/PlayerAnalytics';
import { ViewerAnalytics_viewer_stats_rows } from './__generated__/ViewerAnalytics';
import { TimeRangeEnum } from './queryParams';

export type StatsRow =
  | PlayerAnalytics_player_stats_rows
  | ViewerAnalytics_viewer_stats_rows;

export type GameDescriptorEntry = StatsRow['gameDescriptor'];
export type PlayerEntry = Nullable<ViewerAnalytics_viewer_stats_rows['player']>;

type AnalyticsParamOptions = {
  key: 'rows' | 'columns' | 'values';
  value: string;
};

export type AddAnalyticsParamFn = (options: AnalyticsParamOptions) => void;
export type RemoveAnalyticsParamFn = (options: AnalyticsParamOptions) => void;
export type SetTimeRange = (newRange: TimeRangeEnum | string) => void;
export type SetStartOrEndDate = (newDate: Date) => void;

export type SwitchAnalyticsParamsPositionsFn = (options: {
  key: AnalyticsParamOptions['key'];
  fromIndex: number;
  toIndex: number;
}) => void;

const mapTimePeriod = (granularity: GranularityEnum) => ({
  granularity,
  timePeriod: true,
});

const timeRangeToVariablesMap: Partial<
  Record<string, { granularity: GranularityEnum; timePeriod?: boolean }>
> = {
  all: { granularity: GranularityEnum.All },
  day: mapTimePeriod(GranularityEnum.Day),
  hour: mapTimePeriod(GranularityEnum.Hour),
  minute: mapTimePeriod(GranularityEnum.Minute),
  month: mapTimePeriod(GranularityEnum.Month),
  quarter: mapTimePeriod(GranularityEnum.Quarter),
  second: mapTimePeriod(GranularityEnum.Second),
  week: mapTimePeriod(GranularityEnum.Week),
  year: mapTimePeriod(GranularityEnum.Year),
};

export const mapTimeRangeToVariables = (identifier: string) => {
  const matched = timeRangeToVariablesMap[identifier];

  if (matched) {
    return matched;
  }

  console.error(`Could not map time range ${identifier}`);
  return {};
};

const getVariablesFromDatesMap: Partial<
  Record<
    TimeRangeEnum,
    (
      startDate: Nullable<Date>,
      endDate: Nullable<Date>,
    ) => { from: Date; to: Date }
  >
> = {
  [TimeRangeEnum.LastMonth]: () => {
    const d = subMonths(new Date(), 1);
    return {
      from: startOfMonth(d),
      to: endOfMonth(d),
    };
  },
  [TimeRangeEnum.ThisMonth]: () => ({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  }),
  [TimeRangeEnum.LastWeek]: () => {
    const d = subWeeks(new Date(), 1);
    return {
      from: startOfWeek(d, { weekStartsOn: 1 }),
      to: endOfWeek(d, { weekStartsOn: 1 }),
    };
  },
  [TimeRangeEnum.ThisWeek]: () => ({
    from: startOfWeek(new Date(), { weekStartsOn: 1 }),
    to: endOfWeek(new Date(), { weekStartsOn: 1 }),
  }),
  [TimeRangeEnum.Yesterday]: () => {
    const d = subDays(new Date(), 1);
    return {
      from: startOfDay(d),
      to: endOfDay(d),
    };
  },
  [TimeRangeEnum.Today]: () => ({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  }),
  [TimeRangeEnum.Custom]: (startDate, endDate) => ({
    from: startDate || startOfDay(new Date()),
    to: endDate || endOfDay(new Date()),
  }),
};

const variablesFromDates = (
  range: TimeRangeEnum | string,
  startDate?: Nullable<Date>,
  endDate?: Nullable<Date>,
) => {
  const getFromRange = getVariablesFromDatesMap[range as TimeRangeEnum];

  if (getFromRange) {
    return getFromRange(startDate, endDate);
  }

  const d = new Date();
  d.setFullYear(Number(range));
  return {
    from: startOfYear(d),
    to: endOfYear(d),
  };
};

export const stringVariablesFromDates = (
  range: TimeRangeEnum | string,
  startDate?: Nullable<Date>,
  endDate?: Nullable<Date>,
) => {
  const { from, to } = variablesFromDates(range, startDate, endDate);

  return {
    from: formatISO(from),
    to: formatISO(to),
  };
};
