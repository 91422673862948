import React, {
  Dispatch,
  FC,
  Reducer,
  createContext,
  useContext,
  useReducer,
} from 'react';

export enum Visualization {
  Table,
}

type AnalyticsState = {
  visualization: Visualization;
};

type SetVisualizationAction = {
  type: 'SET_VISUALIZATION';
  visualization: Visualization;
};

type AnalyticsAction = SetVisualizationAction;

type AnalyticsDispatch = Dispatch<AnalyticsAction>;

const getInitialState = ({
  visualization = DEFAULT_VISUALIZATION,
}: {
  visualization?: Visualization;
}) => ({
  visualization,
});

const DEFAULT_VISUALIZATION = Visualization.Table;

const StateContext = createContext<AnalyticsState>(getInitialState({}));
const DispatchContext = createContext<AnalyticsDispatch>(() => {});

export const useAnalyticsState = () => useContext(StateContext);
export const useAnalyticsDispatch = () => useContext(DispatchContext);

const reducer: Reducer<AnalyticsState, AnalyticsAction> = (state, action) => {
  switch (action.type) {
    case 'SET_VISUALIZATION':
      return {
        ...state,
        visualization: action.visualization,
      };
    default:
      return state;
  }
};

export const AnalyticsProvider: FC<{
  initialVisualization?: Visualization;
}> = ({ children, initialVisualization }) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState({ visualization: initialVisualization }),
  );

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};
