import loadable, { LoadableComponent } from '@loadable/component';
import React from 'react';

import { Visualization, useAnalyticsState } from '../Context';
import { StatsRow } from '../helpers';

const TableVisualization = loadable(() => import('./TableVisualization'));

export type VisualizationProps = {
  columns: string[];
  rows: string[];
  values: string[];
  stats: StatsRow[];
};

const useGetVisualizationComponent = (): LoadableComponent<VisualizationProps> | null => {
  const { visualization } = useAnalyticsState();

  switch (visualization) {
    case Visualization.Table:
      return TableVisualization;
    default:
      return null;
  }
};

export const VisualizationView = (props: VisualizationProps) => {
  const Component = useGetVisualizationComponent();

  if (Component) {
    return <Component {...props} />;
  }

  return null;
};
