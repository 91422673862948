import classNames from 'classnames';
import { isEqual } from 'date-fns';
import { keyBy, range } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';

import {
  DateTimeField,
  InlineIconButton,
  NakedForm,
  OptionsMenu,
  SelectField,
} from '@/components';
import { DotsHorizontal, PlusIcon, TrashIcon } from '@/components/icons';
import { Nullable } from '@/types';
import {
  AddAnalyticsParamFn,
  RemoveAnalyticsParamFn,
  SetStartOrEndDate,
  SetTimeRange,
  SwitchAnalyticsParamsPositionsFn,
} from './helpers';
import { TimeRangeEnum } from './queryParams';
import {
  checkHasTimeRangeDimension,
  dimensionsSchema,
  getValueConfig,
  timePeriodDimensionsMap,
  valuesSchema,
} from './schema';

const FROM_YEAR = 2020;

type Props = {
  addParam: AddAnalyticsParamFn;
  switchQueryIndexes: SwitchAnalyticsParamsPositionsFn;
  removeParam: RemoveAnalyticsParamFn;
  setTimeRange: SetTimeRange;
  setStartDate: SetStartOrEndDate;
  setEndDate: SetStartOrEndDate;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  timeRange: TimeRangeEnum | string;
  columns: string[];
  rows: string[];
  values: string[];
};

const repeatableValuesAcrossAxis: Record<string, true> = {
  all: true,
};

function useAvailableValues(
  key: 'rows' | 'columns' | 'values',
  selectedValues: string[],
) {
  const allValues = key === 'values' ? valuesSchema : dimensionsSchema;

  const selectedDimensionIdentifiersMap = useMemo(() => {
    const selectedTimeRangeValues = checkHasTimeRangeDimension(selectedValues)
      ? timePeriodDimensionsMap
      : [];

    return keyBy((x) => x, [
      ...selectedValues,
      ...Object.keys(selectedTimeRangeValues),
    ]);
  }, [selectedValues]);

  return useMemo(() => {
    return allValues.filter((x) => {
      if (
        x.name in selectedDimensionIdentifiersMap &&
        !repeatableValuesAcrossAxis[x.name]
      ) {
        return false;
      }
      return true;
    });
  }, [allValues, selectedDimensionIdentifiersMap]);
}

const timeRangeLabels: Record<TimeRangeEnum, string> = {
  [TimeRangeEnum.Custom]: 'Custom',
  [TimeRangeEnum.LastMonth]: 'Last Month',
  [TimeRangeEnum.LastWeek]: 'Last Week',
  [TimeRangeEnum.ThisMonth]: 'This Month',
  [TimeRangeEnum.ThisWeek]: 'This Week',
  [TimeRangeEnum.Today]: 'Today',
  [TimeRangeEnum.Yesterday]: 'Yesterday',
};

type AnalyticsKey = 'rows' | 'columns' | 'values';

const analyticsKeyToLabel: Record<AnalyticsKey, string> = {
  rows: 'Rows',
  columns: 'Columns',
  values: 'Values',
};

function ParamOptions({
  addParam,
  switchQueryIndexes,
  analyticsKey,
  removeParam,
  selectedValues,
  usedDimensions,
}: {
  addParam: Props['addParam'];
  switchQueryIndexes: Props['switchQueryIndexes'];
  analyticsKey: AnalyticsKey;
  removeParam: Props['removeParam'];
  selectedValues: string[];
  usedDimensions?: string[];
}) {
  const availableValues = useAvailableValues(
    analyticsKey,
    usedDimensions || selectedValues,
  );

  const analyticsKeyLabel = analyticsKeyToLabel[analyticsKey] || analyticsKey;

  return (
    <div>
      <header className="mb-2">
        <div className="inline-block dark:text-gray-200">
          {analyticsKeyLabel}
        </div>
        <OptionsMenu
          placement="bottom"
          maxHeight={250}
          items={availableValues.map((value) => ({
            content: value.label,
            onClick: () => addParam({ key: analyticsKey, value: value.name }),
          }))}
          icon={<PlusIcon />}
          filterItem={(filter, item) =>
            item.content.toLowerCase().includes(filter.toLowerCase())
          }
        />
      </header>

      <DragDropContext
        onDragEnd={(a) =>
          a.destination &&
          switchQueryIndexes({
            key: analyticsKey,
            fromIndex: a.source.index,
            toIndex: a.destination?.index,
          })
        }
      >
        <Droppable droppableId={analyticsKey}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              className={classNames('space-y-2', {
                'bg-gray-100 dark:bg-gray-500':
                  droppableSnapshot.draggingFromThisWith,
              })}
            >
              {selectedValues.map((value, index) => {
                const valueConfig = getValueConfig(value);

                return (
                  <Draggable key={value} draggableId={value} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        className={classNames(
                          'flex items-center justify-between p-1 border dark:border-gray-600 w-full dark:text-gray-200',
                          {
                            'bg-gray-100 dark:bg-gray-700':
                              draggableSnapshot.isDragging,
                          },
                        )}
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                      >
                        <span>
                          <InlineIconButton
                            type="button"
                            {...draggableProvided.dragHandleProps}
                            className="mr-1"
                          >
                            <DotsHorizontal />
                          </InlineIconButton>
                          {valueConfig?.label || value}
                        </span>

                        <InlineIconButton
                          type="button"
                          onClick={() =>
                            removeParam({ key: analyticsKey, value })
                          }
                        >
                          <TrashIcon />
                        </InlineIconButton>
                      </div>
                    )}
                  </Draggable>
                );
              })}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

const Settings = ({
  addParam,
  switchQueryIndexes,
  columns,
  endDate,
  removeParam,
  rows,
  setEndDate,
  setStartDate,
  setTimeRange,
  startDate,
  timeRange,
  values,
}: Props) => {
  const methods = useForm<{
    timeRange: Props['timeRange'];
    startDate: Props['startDate'];
    endDate: Props['endDate'];
  }>({
    defaultValues: {
      timeRange,
      startDate,
      endDate,
    },
  });

  const usedDimensions = useMemo(() => [...columns, ...rows], [columns, rows]);

  const [formTimeRange, formStartDate, formEndDate] = methods.watch([
    'timeRange',
    'startDate',
    'endDate',
  ]);

  const today = useMemo(() => new Date(), []);

  const sharedOptionsProps = {
    switchQueryIndexes: switchQueryIndexes,
    addParam: addParam,
    removeParam: removeParam,
  };

  useEffect(() => {
    if (formTimeRange !== timeRange) {
      setTimeRange(formTimeRange);
    }
  }, [formTimeRange, setTimeRange, timeRange]);

  useEffect(() => {
    if (formStartDate && (!startDate || !isEqual(formStartDate, startDate))) {
      setStartDate(formStartDate);
    }
  }, [formStartDate, setStartDate, startDate]);

  useEffect(() => {
    if (formEndDate && (!endDate || !isEqual(formEndDate, endDate))) {
      setEndDate(formEndDate);
    }
  }, [formEndDate, setEndDate, endDate]);

  return (
    <div className="space-y-2">
      <NakedForm onSubmit={() => {}} methods={methods}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <SelectField
            title="Time Range"
            name="timeRange"
            id="analytics-block-settings__timeRange"
            options={[
              ...range(FROM_YEAR, today.getFullYear() + 1).map((year) => ({
                label: String(year),
                value: String(year),
              })),
              ...Object.values(TimeRangeEnum).map((r) => ({
                label: timeRangeLabels[r] ?? r,
                value: r,
              })),
            ]}
          />
          {formTimeRange === TimeRangeEnum.Custom && (
            <>
              <DateTimeField
                title="Start Date"
                name="startDate"
                id="analytics-block-settings__startDate"
                maxDate={today}
              />
              <DateTimeField
                title="End Date"
                name="endDate"
                id="analytics-block-settings__endDate"
              />
            </>
          )}
        </div>
      </NakedForm>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        <ParamOptions
          analyticsKey="columns"
          selectedValues={columns}
          usedDimensions={usedDimensions}
          {...sharedOptionsProps}
        />
        <ParamOptions
          analyticsKey="rows"
          selectedValues={rows}
          usedDimensions={usedDimensions}
          {...sharedOptionsProps}
        />
        <ParamOptions
          analyticsKey="values"
          selectedValues={values}
          {...sharedOptionsProps}
        />
      </div>
    </div>
  );
};

export default Settings;
