import { useCallback, useMemo } from 'react';
import { gql, useQuery } from 'urql';

import { useDataSource } from '@/contexts';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { swap } from '@/utils/swap';
import {
  ViewerAnalytics,
  ViewerAnalyticsVariables,
} from './__generated__/ViewerAnalytics';
import {
  AddAnalyticsParamFn,
  RemoveAnalyticsParamFn,
  SetStartOrEndDate,
  SetTimeRange,
  SwitchAnalyticsParamsPositionsFn,
  mapTimeRangeToVariables,
  stringVariablesFromDates,
} from './helpers';
import { viewerQueryParams } from './queryParams';
import { getValueConfig, timePeriodDimensionsMap } from './schema';

export const mapAnalyticsParamToVariables = (
  identifier: string,
): Partial<ViewerAnalyticsVariables> => {
  if (identifier in timePeriodDimensionsMap) {
    return mapTimeRangeToVariables(identifier);
  }

  const valueConfig = getValueConfig(identifier);

  if (valueConfig) {
    return {
      [valueConfig.fieldName || valueConfig.name]: true,
    };
  }

  return { [identifier]: true };
};

const VIEWER_STATS_QUERY = gql`
  query ViewerAnalytics(
    $affiliateId: Boolean!
    $allWins: Boolean!
    $amountAdjustments: Boolean!
    $amountBonusAdjustments: Boolean!
    $amountCashbacks: Boolean!
    $amountDeposits: Boolean!
    $amountFailedDeposits: Boolean!
    $amountFailedWithdrawals: Boolean!
    $amountFtd: Boolean!
    $amountReversals: Boolean!
    $amountWithdrawableWinnings: Boolean!
    $amountWithdrawals: Boolean!
    $birthYear: Boolean!
    $brand: Boolean!
    $clientCountryCode: Boolean!
    $currency: Boolean!
    $device: Boolean!
    $deviceFingerprint: Boolean!
    $exchangeRateBaseCurrency: String
    $exchangeRateDate: LocalDate
    $from: OffsetDateTime!
    $ftd: Boolean!
    $ftdOfNrc: Boolean!
    $gameDescriptor: Boolean!
    $gameId: Boolean!
    $gameProvider: Boolean!
    $gameSessionId: Boolean!
    $gender: Boolean!
    $ggr: Boolean!
    $granularity: GranularityEnum!
    $hold_: Boolean!
    $isBot: Boolean!
    $isIncognito: Boolean!
    $jpc: Boolean!
    $margin: Boolean!
    $ngr: Boolean!
    $nrc: Boolean!
    $nrcByNsc: Boolean!
    $nsc: Boolean!
    $numAdjustments: Boolean!
    $numBlockAccountRequests: Boolean!
    $numCancelSelfExclusionRequests: Boolean!
    $numCashbacks: Boolean!
    $numCloseAccountRequests: Boolean!
    $numDeposits: Boolean!
    $numFailedDeposits: Boolean!
    $numFailedWithdrawals: Boolean!
    $numReopenAccountRequests: Boolean!
    $numReversals: Boolean!
    $numSelfExclusionRequests: Boolean!
    $numTotalCloseAccountRequests: Boolean!
    $numUniqueActivePlayers: Boolean!
    $numUniqueDepositors: Boolean!
    $numUniquePlayers: Boolean!
    $numUniqueSessions: Boolean!
    $numWithdrawals: Boolean!
    $orderBy: [ColumnOrderSpecType!]
    $os: Boolean!
    $paymentProvider: Boolean!
    $player: Boolean!
    $playerId: Boolean!
    $playerSessionId: Boolean!
    $residenceCountryCode: Boolean!
    $timePeriod: Boolean!
    $timeZone: String
    $to: OffsetDateTime!
    $turnover: Boolean!
    $wagers: Boolean!
    $dataSourceVersion: DataSourceVersionEnum
  ) {
    viewer {
      id
      stats(
        dataSourceVersion: $dataSourceVersion
        from: $from
        to: $to
        granularity: $granularity
        orderBy: $orderBy
        exchangeRateBaseCurrency: $exchangeRateBaseCurrency
        exchangeRateDate: $exchangeRateDate
        timeZone: $timeZone
      ) {
        error
        rows {
          timePeriod @include(if: $timePeriod)
          player @include(if: $player) {
            __typename
            id
            firstName
            lastName
            phoneNumber
            email
            isSmsMarketingAccepted
            isEmailMarketingAccepted
            accountStatus {
              status
            }
          }
          gameDescriptor @include(if: $gameDescriptor) {
            json
          }
          affiliateId @include(if: $affiliateId)
          allWins @include(if: $allWins)
          amountAdjustments @include(if: $amountAdjustments)
          amountBonusAdjustments @include(if: $amountBonusAdjustments)
          amountCashbacks @include(if: $amountCashbacks)
          amountDeposits @include(if: $amountDeposits)
          amountFailedDeposits @include(if: $amountFailedDeposits)
          amountFailedWithdrawals @include(if: $amountFailedWithdrawals)
          amountFtd @include(if: $amountFtd)
          amountReversals @include(if: $amountReversals)
          amountWithdrawableWinnings @include(if: $amountWithdrawableWinnings)
          amountWithdrawals @include(if: $amountWithdrawals)
          birthYear @include(if: $birthYear)
          brand @include(if: $brand)
          clientCountryCode @include(if: $clientCountryCode)
          currency @include(if: $currency)
          device @include(if: $device)
          deviceFingerprint @include(if: $deviceFingerprint)
          ftd @include(if: $ftd)
          ftdOfNrc @include(if: $ftdOfNrc)
          gameId @include(if: $gameId)
          gameProvider @include(if: $gameProvider)
          gameSessionId @include(if: $gameSessionId)
          gender @include(if: $gender)
          ggr @include(if: $ggr)
          hold_ @include(if: $hold_)
          isBot @include(if: $isBot)
          isIncognito @include(if: $isIncognito)
          jpc @include(if: $jpc)
          margin @include(if: $margin)
          ngr @include(if: $ngr)
          nrc @include(if: $nrc)
          nrcByNsc @include(if: $nrcByNsc)
          nsc @include(if: $nsc)
          numAdjustments @include(if: $numAdjustments)
          numBlockAccountRequests @include(if: $numBlockAccountRequests)
          numCancelSelfExclusionRequests
            @include(if: $numCancelSelfExclusionRequests)
          numCashbacks @include(if: $numCashbacks)
          numCloseAccountRequests @include(if: $numCloseAccountRequests)
          numDeposits @include(if: $numDeposits)
          numFailedDeposits @include(if: $numFailedDeposits)
          numFailedWithdrawals @include(if: $numFailedWithdrawals)
          numReopenAccountRequests @include(if: $numReopenAccountRequests)
          numReversals @include(if: $numReversals)
          numSelfExclusionRequests @include(if: $numSelfExclusionRequests)
          numTotalCloseAccountRequests
            @include(if: $numTotalCloseAccountRequests)
          numUniqueActivePlayers @include(if: $numUniqueActivePlayers)
          numUniqueDepositors @include(if: $numUniqueDepositors)
          numUniquePlayers @include(if: $numUniquePlayers)
          numUniqueSessions @include(if: $numUniqueSessions)
          numWithdrawals @include(if: $numWithdrawals)
          os @include(if: $os)
          paymentProvider @include(if: $paymentProvider)
          playerId @include(if: $playerId)
          playerSessionId @include(if: $playerSessionId)
          residenceCountryCode @include(if: $residenceCountryCode)
          turnover @include(if: $turnover)
          wagers @include(if: $wagers)
        }
      }
    }
  }
`;

export function useViewerAnalytics() {
  const [query, setQuery] = useQueryParamsWithTime(viewerQueryParams);
  const mappedVariables = mapVariables(query);
  const { dataSource } = useDataSource();

  const updateFilter = useCallback<(values: {}) => void>(
    (values) => setQuery(values, 'replaceIn'),
    [setQuery],
  );

  const addParam = useCallback<AddAnalyticsParamFn>(
    ({ key, value }) => {
      setQuery({ [key]: [...query[key], value] });
    },
    [query, setQuery],
  );

  const removeParam = useCallback<RemoveAnalyticsParamFn>(
    ({ key, value }) => {
      setQuery({ [key]: query[key].filter((a) => a !== value) });
    },
    [query, setQuery],
  );

  const setTimeRange = useCallback<SetTimeRange>(
    (newRange) => {
      setQuery(
        {
          timeRange: newRange,
          startDate: undefined,
          endDate: undefined,
        },
        'replaceIn',
      );
    },
    [setQuery],
  );

  const setStartDate = useCallback<SetStartOrEndDate>(
    (newStartDate) => {
      setQuery({ startDate: newStartDate }, 'replaceIn');
    },
    [setQuery],
  );

  const setEndDate = useCallback<SetStartOrEndDate>(
    (newEndDate) => {
      setQuery({ endDate: newEndDate }, 'replaceIn');
    },
    [setQuery],
  );

  const switchQueryIndexes = useCallback<SwitchAnalyticsParamsPositionsFn>(
    ({ key, fromIndex, toIndex }) => {
      setQuery(
        {
          [key]: swap(query[key], fromIndex, toIndex),
        },
        'replaceIn',
      );
    },
    [setQuery, query],
  );

  const analyticsVariables = [
    ...(query.columns as string[]),
    ...(query.rows as string[]),
    ...(query.values as string[]),
  ].reduce((acc, entry) => {
    return {
      ...acc,
      ...mapAnalyticsParamToVariables(entry),
    };
  }, {});

  const hasValues = query.values.length > 0;

  // broken out to own useMemo for easier debugging than inlining it
  const variables = useMemo(() => {
    return {
      ...mappedVariables,
      ...analyticsVariables,
      ...stringVariablesFromDates(
        query.timeRange,
        query.startDate,
        query.endDate,
      ),
    };
  }, [
    analyticsVariables,
    mappedVariables,
    query.endDate,
    query.startDate,
    query.timeRange,
  ]);

  const [{ data, fetching }, refresh] = useQuery<
    ViewerAnalytics,
    ViewerAnalyticsVariables
  >({
    query: VIEWER_STATS_QUERY,
    variables: {
      ...variables,
      dataSourceVersion: dataSource,
    },
    pause: !hasValues,
    requestPolicy: 'network-only',
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter,
    addParam,
    removeParam,
    columns: query.columns as string[],
    rows: query.rows as string[],
    values: query.values as string[],
    startDate: query.startDate,
    endDate: query.endDate,
    timeRange: query.timeRange,
    setTimeRange,
    setStartDate,
    setEndDate,
    switchQueryIndexes,
    stats: hasValues ? data?.viewer?.stats?.rows : [],
  };
}
